import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SelectProps } from '@material-ui/core';
import { CountryContext } from '~/context/i18n';
import { UseFormMethods, RegisterOptions } from 'react-hook-form';
import { Select, SelectOptions } from '~/components/forms/fields/Select';
import { useLanguageUrl } from '~/state/hooks/useLanguageUrl';
import { WarningCaption } from '~/components/Join/styles';
import { ExternalLink } from '../BaseElements';
import { ROUTES } from '~/config';
import {
  PhoneInput,
  SmsAvailabilityMessage,
} from '~/components/forms/fields/PhoneInput';

interface Props<Form> {
  translationPath: string;
  form: UseFormMethods<Form>;
  validationSchema: Map<keyof Form, RegisterOptions>;
  getErrorMessage: (fieldName: keyof Form, errType?: string) => string;
  variant?: SelectProps['variant'];
  shrinkSelectLabel?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const NotificationSettings: React.FC<Props<any>> = ({
  form,
  translationPath,
  getErrorMessage,
  validationSchema,
  shrinkSelectLabel,
  variant,
}) => {
  const { t } = useTranslation();
  const languageUrl = useLanguageUrl();
  const { country } = useContext(CountryContext);

  const countryCode = country.code.toUpperCase();
  const options: SelectOptions = t(`${translationPath}.notifications.options`, {
    returnObjects: true,
  });
  const selectedOption = form.watch('notificationPreference');
  const phoneRequired = /text/i.test(selectedOption);

  return (
    <>
      <Select
        name="notificationPreference"
        variant={variant}
        options={options}
        formControl={form.control}
        shrinkLabel={shrinkSelectLabel}
        label={t(`${translationPath}.notifications.label`)}
        validationRules={validationSchema.get('notificationPreference')}
        errorMessage={getErrorMessage(
          'notificationPreference',
          form.errors?.notificationPreference?.type
        )}
      />
      {phoneRequired && (
        <PhoneInput
          error={!!form.errors.phoneNumber}
          label={t(`${translationPath}.phoneNumber.label`)}
          inputRef={form.register(validationSchema.get('phoneNumber'))}
          errorMessage={getErrorMessage(
            'phoneNumber',
            form.errors?.phoneNumber?.type
          )}
        />
      )}
      {phoneRequired && countryCode === 'MX' && (
        <SmsAvailabilityMessage>
          {t(`${translationPath}.notifications.smsAvailabilityMessage`)}
        </SmsAvailabilityMessage>
      )}
      {phoneRequired && (
        <WarningCaption>
          <Trans i18nKey="Join.form.notifications.warning">
            <ExternalLink to={`${languageUrl}${ROUTES.terms}`} />
            <ExternalLink to={`${languageUrl}${ROUTES.privacyPolicy}`} />
          </Trans>
        </WarningCaption>
      )}
    </>
  );
};
